main {
  padding-bottom: 85px;
}

.pageLoader {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.progress-container {
  position: absolute;
  width: 100%;
  bottom: -1rem;
  left: 0;
  right: 0;
}
