#apppage .gradient {
  background: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.7),
    rgba(72, 15, 144, 0.4) 100%
  );
  background: -webkit-gradient(
    linear,
    45deg,
    from(rgba(0, 0, 0, 0.7), rgba(72, 15, 144, 0.4) 100%)
  );
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.7),
    rgba(72, 15, 144, 0.4) 100%
  );
}

#apppage .view {
  background-image: url("https://storage.googleapis.com/toloba-app-assets/homepage-backdrop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
}

#apppage h6 {
  line-height: 1.7;
}

#apppage .card {
  background-color: rgba(10, 15, 41, 0.6);
}

#apppage label {
  color: #fff !important;
}

@media only screen and (max-width: 400px) {
  #apppage .animated img {
    max-height: 50vh;
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
  }
}
@media only screen and (max-width: 767px) {
  #apppage .animated img {
    max-height: 35vh;
    transform: translateX(-50%);
    left: 50%;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  #apppage .animated img {
    max-height: 50vh;
    transform: translateX(-50%);
    left: 50%;
    position: relative;
  }
}
@media (max-width: 1000px) and (orientation: landscape) {
  #apppage .view {
    min-height: 150vh;
  }
}
